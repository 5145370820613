<template>  
    <ag-grid-vue  class="ag-grid ag-theme-balham"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          pagination
          :paginationPageSize="1000"
          :paginationPageSizeSelector="false"
          :rowData="rowData"
          rowModelType="serverSide"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressScrollOnNewData
          suppressContextMenu
          suppressMultiSort
          >
    </ag-grid-vue>
</template>

<script>
import { cloneDeep } from 'lodash'
import { taskService } from '@/services';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import LinkCellRenderer from '@/components/Aggrid/CellRenderer/Link';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      const p = self.buildParams(params);
      if (self.canView('STAFF') && self.canView('TASK') && self.canView('PROJECT')) {
        taskService.listHomeDashboard(cloneDeep(p)).then((response) => {
          if (response.data.length == 0) {
            params.success({ rowData: [], rowCount: 0 });
            self.gridApi.showNoRowsOverlay();
          } else {
            taskService.listTasksDashboard(response.data[0].uuId, p).then((tresponse) => {
              params.success({ rowData: tresponse.data, rowCount: tresponse.arg_total });
              self.gridApi.hideOverlay();
              self.gridApi.autoSizeColumns(['name'], false);
            })
          }
        })
        .catch(function(error) {
          console.error(error); // eslint-disable-line no-console
          params.success({ rowData: [], rowCount: self.totalRecords });
          self.gridApi.showNoRowsOverlay();
        });
      }
      else {
        params.success({ rowData: [], rowCount: self.totalRecords });
        self.gridApi.showNoRowsOverlay();
      }
    }
  }
}

export default {
  name: 'MyTaskList',
  components: {
    'ag-grid-vue': AgGridVue,
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    dateTimeCellRenderer: DateTimeCellRenderer,
    percentageCellRenderer: PercentageCellRenderer,
    linkCellRenderer: LinkCellRenderer,
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    userEmail: {type: String, required: true},
    columns: {
      type: Array,
      default: null
    }
  },
  data: function() {
    return {
      gridOptions: null,
      gridApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
    };
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onGridSizeChanged: function(event) {
        self.gridApi.autoSizeColumns(['name'], false);
      },
      onSortChanged: function(event) {
        if (event.source === 'gridOptionsChanged') {
          return; // do not process this sort changed as it does not contain the user's sort column selection
        }
        
        if (event.api.gridBodyCtrl && event.api.gridBodyCtrl.bodyScrollFeature) {
          event.api.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(0)
        }
        //Update column setting
        const columns = event.api.getAllDisplayedColumns()
        self.saveSelectorColumnSettings(columns, false)
      }, 
      onDragStopped: function(event) {
        const columns = event.api.getAllDisplayedColumns()
        self.saveSelectorColumnSettings(columns, false)
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('active_task.grid.name'),
        field: 'name',
        flex: 1,
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          value: function() {
            return this.data.name;
          },
          clicked: function(field) {
            self.$emit('editTask', {taskId: this.data.uuId, projectId: this.data.projectId, callback: self.reloadGrid});
          },
        },
      },
      {
        headerName: this.$t('active_task.grid.project'),
        field: 'project',
        flex: 1,
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          value: function() {
            return this.data.project;
          },
          clicked: function() {
            self.$router.push('/projects/summary/'+this.data.projectId)
          },
        },
      },
      {
        headerName: this.$t('active_task.grid.path'),
        sortable: false,
        field: 'path',
        flex: 2,
      },
      {
        headerName: this.$t('active_task.grid.start'),
        field: 'startTime',
        sort: 'asc',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('active_task.grid.end'),
        field: 'endTime',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('active_task.grid.progress'),
        field: 'progress',
        minWidth: 50,
        maxWidth: 100,
        cellRenderer: 'percentageCellRenderer'
      },
    ];
    this.defaultColDef = {
      resizable: true,
      minWidth: 100,
      menuTabs: []
    };
    this.context = {
      componentParent: self
    };
    
    this.loadColumnSettings(this, this.columns);
  },
  beforeDestroy() {
    this.gridApi = null
  },
  watch: {
    tasks: function(newValue) {
      if(this.gridApi) {
        this.rowData = newValue;
        this.gridApi.redrawRows();
      }
    }
  },
  computed: {
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>No tasks</span>`;
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      const self = this;
      const updateData = () => {
        params.api.setGridOption('serverSideDatasource', new ServerSideDatasource(self));
      };
      updateData();
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
      const params = {
        start: startRow,
        limit: endRow - startRow + 1,
        userEmail: this.userEmail
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId === 'path' ? 'parents' : sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      return params;
    },
    reloadGrid() {
      this.gridApi.refreshServerSide({purge: true});
    }, 
    saveSelectorColumnSettings(columns, skipCheck=true) {
      const getColumnDefs = (c) => {
        return {
          colId: c.colId
          , width: c.actualWidth
          , sort: c.sort != null? c.sort : null
          , sortIndex: c.sortIndex != null? c.sortIndex : null
        }
      }
          
      const newColumns = columns.filter(c => c.colId != 'rowSelector').map(c => getColumnDefs(c))
      const oldColumns = this.columns
      if (skipCheck) {
        this.$emit('columnsUpdated', newColumns);
        return
      }

      let hasChanged = false
      if (oldColumns == null) {
        hasChanged = true
      } else if (oldColumns.length != newColumns.length) {
        hasChanged = true
      } else {
        for (const [index, col] of oldColumns.entries()) {
          if (col.colId != newColumns[index].colId || 
              col.width != newColumns[index].width ||
              col.sort != newColumns[index].sort ||
              col.sortIndex != newColumns[index].sortIndex) {
            hasChanged = true
            break
          }
        }
      }
      if (hasChanged) {
        this.$emit('columnsUpdated', newColumns);
      }
    },
    loadColumnSettings(target, columns) {
      if (target == null || columns == null || columns.length == 0) {
        return
      }
      
      // order the columns based upon the order in 'columns'
      // 0 index column is reserved for rowSelector
      let idx = 1
      columns.forEach(function(col) {
        const index = target.columnDefs.findIndex((c) => c.colId === col.colId || c.field === col.colId)
        if (index !== -1) {
          target.columnDefs.splice(idx++, 0, target.columnDefs.splice(index, 1)[0])
        }
      })
      
      for (const column of target.columnDefs) {
        const setting = columns.filter(c => c.colId === column.colId || c.colId === column.field)
        if (setting.length === 0) {
          if (column.colId != 'rowSelector') {
            column.hide = true
            column.sort = null //reset to null to clean up previous view value
            column.sortIndex = null //reset to null to clean up previous view value
          }
        }
        else {
          column.hide = false
          column.width = setting[0].width
          column.sort = setting[0].sort
          column.sortIndex = setting[0].sortIndex
        }
      }

      //Rearrange sort Index if necessary
      const columnsWithSortIndex = target.columnDefs.filter(i => i.sortIndex != null);
      if (columnsWithSortIndex.length > 0) {
        columnsWithSortIndex.sort((a, b) => {
          if (a.sortIndex < b.sortIndex) {
            return -1;
          } else if (a.sortIndex > b.sortIndex) {
            return 1;
          }
          return 0;
        })
        
        for (let i = 0, len = columnsWithSortIndex.length; i < len; i++) {
          columnsWithSortIndex[i].sortIndex = i;
        }
      }

      if (target.gridApi != null && !target.gridApi.isDestroyed()) {
        target.gridApi.setGridOption('columnDefs', [])
        target.gridApi.setGridOption('columnDefs', cloneDeep(target.columnDefs))

      }
    }
  }
}
</script>
